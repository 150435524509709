export enum HotelsDashboardPage {
  RoomTypes = 'RoomTypes',
  RoomRates = 'RoomRates',
  Extras = 'Extras',
}
export const mapHotelsPageToPageIndexInSidebar: Record<
  HotelsDashboardPage,
  number
> = {
  [HotelsDashboardPage.RoomTypes]: 2,
  [HotelsDashboardPage.RoomRates]: 3,
  [HotelsDashboardPage.Extras]: 4,
};

export const HOTELS_MULTI_DASHBOARD_COMPONENT_ID =
  '1c5b7dec-159e-48cf-b618-6d77d7a17f8c';

export const HOTEL_RUNNER_APP_ID = '826670ba-3a6b-4157-ac72-7c4fca9ce220';
