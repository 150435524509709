import {
  ElementType,
  DefaultTextStyle,
  TemplateDefaultColor,
  WebComponentData,
} from '@wix/ambassador-app-service-webapp/types';
import {
  EditorSDK,
  ColorPalete,
  ComponentRef,
  DeviceType,
  PageRef,
  gfppOriginStrings,
} from '@wix/platform-editor-sdk';
import { TOKEN } from '../components/webComponent/editor.controller';
import {
  getCustomElementStructure,
  IWebComponent,
} from '../components/webComponent/elements/customElement';
import { addComponent, findComponentsByType } from './editor';
import { checkPageSlug } from './slugCheck';
import { appSettingsClickOnSettingsButton } from '@wix/bi-logger-custom-elements/v2';
import { FlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import {
  HOTELS_MULTI_DASHBOARD_COMPONENT_ID,
  HOTEL_RUNNER_APP_ID,
} from '../hotels/hotels.constants';

export const filterPage = (components: IWebComponent[]) => {
  return components.filter(
    (comp) => (comp.data as WebComponentData).type === ElementType.PAGE
  );
};

export const filterWidgets = (components: IWebComponent[]) => {
  return components.filter(
    (comp) =>
      (comp.data as WebComponentData)?.type === ElementType.WIDGET ||
      (comp.data as WebComponentData)?.type === undefined
  );
};

export const findPagesThatHaveNotBeenInstalledInThePast = async (
  components: IWebComponent[],
  editorSDK: FlowEditorSDK
) => {
  const pages = await editorSDK.pages.data.getAll('');
  const flatTpaPagesId = pages.map((page) => page.tpaPageId);

  return components.filter(
    (component) =>
      !flatTpaPagesId.includes(
        `${component.data?.tagName}|${component.componentId}`
      )
  );
};

export const createNewPage = async ({
  componentDefinition,
  editorSDK,
  appDefinitionId,
}: {
  componentDefinition: IWebComponent;
  editorSDK: FlowEditorSDK;
  appDefinitionId: string;
}) => {
  const {
    showPageInMenu = false,
    tagName,
    slug,
    modalTitle,
  } = componentDefinition.data as WebComponentData;
  const pages = await editorSDK.pages.data.getAll('');

  const validPageSlug = checkPageSlug(
    slug as string,
    pages.map((page) => page.pageUriSEO)
  );
  return editorSDK.pages.add(TOKEN, {
    title: modalTitle ?? (tagName as string),
    shouldAddMenuItem: showPageInMenu,
    shouldNavigateToPage: true,
    definition: {
      type: 'Page',
      componentType: 'mobile.core.components.Page',
      data: {
        tpaPageId: `${tagName}${
          componentDefinition.componentId
            ? `|${componentDefinition.componentId}`
            : ``
        }`,
        managingAppDefId: appDefinitionId,
        pageUriSEO: validPageSlug || tagName,
        metaData: {
          pageId: tagName as string,
        },
      },
    },
  });
};

export const addComponentToPage = async ({
  editorSDK,
  componentDefinition,
  instanceId,
  applicationId,
  metaSiteData,
  fullWidth = false,
  isPage = false,
  pageRef,
  flowApi,
}: {
  flowApi: FlowAPI;
  editorSDK: FlowEditorSDK;
  instanceId: string;
  applicationId: string;
  metaSiteData: Record<string, any>;
  componentDefinition: IWebComponent;
  fullWidth?: boolean;
  isPage?: boolean;
  pageRef: PageRef;
}) => {
  const formFactor = await editorSDK.editor.info.getEditorMode(TOKEN);
  const fonts: Record<string, TFont> =
    await editorSDK.document.theme.fonts.getMap(TOKEN);
  const colors: ColorPalete = await editorSDK.document.theme.colors.getAll(
    TOKEN
  );

  const customElementComponentRef = await addComponent({
    editorSDK,
    componentDefinition: getCustomElementStructure({
      applicationId,
      data: componentDefinition.data ?? {},
      instanceId,
      metaSiteDataAndDefault: {
        ...metaSiteData,
      },
      formFactor,
      widgetId: componentDefinition.componentId,
      isPage,
    }),
    fullWidth,
    pageRef,
  });

  const currentElementRef = await findComponentsByType(
    editorSDK,
    customElementComponentRef,
    'wixui.CustomElementComponent'
  );

  await editorSDK.document.controllers.saveConfiguration(TOKEN, {
    controllerRef: customElementComponentRef,
    config: { componentId: componentDefinition.componentId, ...metaSiteData },
  });

  await editorSDK.document.controllers.connect(TOKEN, {
    controllerRef: customElementComponentRef,
    connectToRef: currentElementRef[0],
    role: 'webComponent',
    connectionConfig: { time: new Date().getTime() },
  });
  // if fetchInitialData is set we will send there initial data + theme
  componentDefinition.data?.gfppSettings?.fetchInitialData &&
    saveSettingsToThirdPartyService({
      appId: applicationId,
      flowApi,
      url: componentDefinition.data?.gfppSettings?.fetchInitialData,
      settings: {
        data: {},
        componentId: componentDefinition.componentId,
        instanceId,
        theme: {
          colors,
          fonts,
        },
      },
    });
};

export type TFont = {
  style: string;
  variant: string;
  weight: string;
  size: string;
  lineHeight: string;
  family: string;
  color: string;
  bold: boolean;
  italic: boolean;
  fontWithFallbacks: string;
  cssColor: string;
};

// TODO: refactor this function
export function getDataInJsonFormatAndOverrideFontsAndColors(
  json: any,
  newObj: any = {},
  fonts: Record<string, TFont>,
  colors: ColorPalete
) {
  if (json.key) {
    newObj[json.key] = json.defaultValue ?? json.value ?? json.selected ?? '';
  }
  if (json.items) {
    json.items.map((item: any) =>
      getDataInJsonFormatAndOverrideFontsAndColors(item, newObj, fonts, colors)
    );
  }
  for (const prop in json) {
    if (!(typeof json[prop] === 'object' && !Array.isArray(json?.[prop]))) {
      continue;
    }
    if (json?.[prop]?.key) {
      // put in default value the proper value for text style
      if (prop === 'textStyle') {
        newObj[json[prop].key] = {
          defaultTextStyle:
            fonts?.[textStyleToEditorFont(json?.[prop]?.defaultTextStyle)]
              ?.fontWithFallbacks,
          customColor: json?.[prop]?.customColor
            ? json?.[prop]?.customColor
            : {
                value:
                  colors?.[
                    colorPickerToEditorColor(json?.[prop]?.templateColor)
                  ] ?? colors.color_0,
              },
        };
      } else if (prop === 'colorSelectLabeled') {
        // put in default value the proper value for color
        newObj[json[prop].key] = {
          customColor: json?.[prop]?.customColor
            ? json?.[prop]?.customColor
            : {
                value:
                  colors?.[
                    colorPickerToEditorColor(json?.[prop]?.templateColor)
                  ] ?? colors.color_0,
              },
          ...(json?.[prop]?.defaultData ? json?.[prop]?.defaultData : {}),
        };
      } else {
        newObj[json[prop].key] =
          json?.[prop]?.defaultValue ??
          json?.[prop]?.value ??
          json?.[prop]?.selected ??
          '';
      }
    } else {
      getDataInJsonFormatAndOverrideFontsAndColors(
        json[prop],
        newObj,
        fonts,
        colors
      );
    }
  }
  return newObj;
}

export function textStyleToEditorFont(type: DefaultTextStyle): string {
  const mapper = {
    [DefaultTextStyle.TITLE]: 'font_5',
    [DefaultTextStyle.LOWER_HIERARCHY_TEXTS]: 'font_8',
    [DefaultTextStyle.PARAGRAPH]: 'font_7',
  };
  return mapper?.[type] ?? mapper[DefaultTextStyle.TITLE];
}
export function colorPickerToEditorColor(
  type: TemplateDefaultColor
): keyof ColorPalete {
  const mapper = {
    [TemplateDefaultColor.BACKGROUND]: 'color_1' as keyof ColorPalete,
    [TemplateDefaultColor.SECONDARY_TEXTS]: 'color_4' as keyof ColorPalete,
    [TemplateDefaultColor.MAIN_TEXT_AND_ICONS]: 'color_5' as keyof ColorPalete,
    [TemplateDefaultColor.BORDERS_AND_DIVIDERS]: 'color_8' as keyof ColorPalete,
    [TemplateDefaultColor.BUTTONS_AND_LINKS]: 'color_8' as keyof ColorPalete,
  };
  return mapper?.[type] ?? mapper[TemplateDefaultColor.BACKGROUND];
}

export const updateViewMode = async ({
  editorSDK,
  compRef,
  mode,
  appDefinitionId,
  reset = false,
}: {
  mode?: DeviceType;
  compRef: ComponentRef;
  editorSDK: FlowEditorSDK;
  appDefinitionId: string;
  reset?: boolean;
}) => {
  const connections = await editorSDK.document.controllers.listConnections(
    TOKEN,
    { componentRef: compRef }
  );

  // getting the connected component from controller
  const controllerRef: any = connections[0]?.controllerRef;

  // defend when we don't have connected component
  // TODO: looks like the update in settings-panel
  // not working as it should and we here update it again check why
  if (controllerRef) {
    // getting new data from component after changing it in settings panel
    const { config } = await editorSDK.document.controllers.getData(TOKEN, {
      controllerRef,
    });

    // getting old data from component
    const componentData = await editorSDK.components.data.get(TOKEN, {
      componentRef: compRef,
    });

    const currentInitialAttributes = JSON.parse(
      // @ts-expect-error
      componentData.initialAttributes
    );
    const currentWixConfig = JSON.parse(currentInitialAttributes.wixconfig);
    // make sure that only apster and tolstoy has data as attribute.
    if (
      ![
        'aeecfaf9-9455-4457-b99d-265829eaf39f',
        '36c2efd9-e20e-4022-a834-e64d61132e6e',
      ].includes(appDefinitionId)
    ) {
      currentWixConfig.data = null;
    }
    const newWidgetData = currentWixConfig.data
      ? {
          ...currentWixConfig,
          data: {
            ...currentWixConfig.data,
            ...config,
          },
          formFactor: mode ?? currentWixConfig.formFactor,
          deviceType: mode ?? currentWixConfig.deviceType,
        }
      : {
          ...currentWixConfig,
          formFactor: mode ?? currentWixConfig.formFactor,
          deviceType: mode ?? currentWixConfig.deviceType,
        };

    const currentWixSettings = JSON.parse(
      currentInitialAttributes?.wixsettings || '{}'
    );

    let newSettingsData = {
      ...currentWixSettings,
      ...config,
    };

    if (reset) {
      newSettingsData = { componentId: newSettingsData.componentId };
      editorSDK.controllers.saveConfiguration(TOKEN, {
        controllerRef,
        config: newSettingsData,
      });
    }

    const mergedConfig = newSettingsData;
    const attributes = {
      wixconfig: JSON.stringify(newWidgetData),
      wixsettings: JSON.stringify({ ...mergedConfig }),
      ts: new Date().getTime(),
    };
    if (JSON.stringify(attributes).length > 1023) {
      attributes.wixsettings = JSON.stringify({
        message:
          'Seems that you reached the data limitation. plesae set "Data Endpoint URL" as an option to save the data to external DB',
      });
    }
    // @ts-expect-error
    componentData.initialAttributes = JSON.stringify(attributes);

    // update the new data in component
    editorSDK.components.data.update(TOKEN, {
      componentRef: compRef,
      data: componentData,
    });
  }
};

interface SaveSettingsToTPService {
  url: string;
  settings: object;
  flowApi: FlowAPI;
  appId: string;
}

export const saveSettingsToThirdPartyService = async ({
  url,
  settings,
  flowApi,
  appId,
}: SaveSettingsToTPService): Promise<void> => {
  if (!url) {
    return;
  }

  try {
    await flowApi.httpClient.post(
      'https://editor.wix.com/_serverless/web-component-wrapper-signer/send-data',
      {
        url,
        settingsData: settings,
        appId,
      }
    );
  } catch (error) {
    // probably have a proper error handling
    console.error(`Failed to send settings to ${url}`);
    return;
  }
};

export const fetchSecret = async (appId: string, subdomain = 'editor') => {
  return '';
  // try {
  //   const appService = AppServiceWebapp(
  //     `https://${subdomain}.wix.com/_api/app-service-webapp/`,
  //   ).Apps();
  //   const secret = await appService().getAppSecret({ appId });
  //   return secret.appSecret;
  // } catch (error) {
  //   console.error(error);
  // }
};

export const dashboardAction = ({
  multiDashboardData,
  appDefId,
  instance,
  editorSDK,
}: {
  multiDashboardData?: string | undefined;
  appDefId: string;
  instance: string;
  editorSDK: FlowEditorSDK;
}) => {
  const multidashboardTwicks: Record<string, string> = {
    'ee4f5349-bf6f-46a8-aaf6-8cba4f9726ff': encodeURIComponent(
      'aeb30219-80d9-4d26-8dbf-503d47dd93dd|0'
    ), // // staging app -  search-bar-widget
    [HOTEL_RUNNER_APP_ID]: encodeURIComponent(
      `${HOTELS_MULTI_DASHBOARD_COMPONENT_ID}|0`
    ), // // production appsearch-bar-widget
  };
  let multidashboardPath = '';

  if (multiDashboardData) {
    multidashboardPath = `/${encodeURIComponent(multiDashboardData + '|0')}`;
  }

  if (multidashboardTwicks[appDefId]) {
    multidashboardPath = `/${multidashboardTwicks[appDefId]}`;
  }

  const pathToDashboard = `app/${appDefId}${multidashboardPath}?instance=${instance}`;

  return editorSDK.editor.openDashboardPanel(TOKEN, {
    url: pathToDashboard,
    closeOtherPanels: false,
  });
};

export const reportBiEventGfppSettingsClicked = async (
  flowAPI: FlowAPI,
  editorSDK: FlowEditorSDK,
  widgetGfppClickedEvent: CustomEvent<{
    id: string;
    componentRef: ComponentRef;
    gfppOrigin: gfppOriginStrings;
  }>,
  webComponents: IWebComponent[]
) => {
  const { detail } = widgetGfppClickedEvent;

  flowAPI.bi?.report(
    appSettingsClickOnSettingsButton({
      app_id: await editorSDK.info.getAppDefinitionId(TOKEN),
      msid: await editorSDK.info.getMetaSiteId(TOKEN),
      element_type: await getCustomElementTagName(
        editorSDK,
        detail.componentRef,
        webComponents
      ),
    })
  );
};

export const getCustomElementTagName = async (
  editorSDK: FlowEditorSDK,
  componentRef: ComponentRef,
  webComponents: IWebComponent[]
) => {
  const { controllerType: componentId }: any =
    await editorSDK.components.data.get(TOKEN, {
      componentRef,
    });
  const relevantComponent = webComponents.find(
    (webComp) => webComp.componentId === componentId
  );

  return relevantComponent?.data?.tagName;
};

export const openWidgetSelectorModal = async ({
  editorSDK,
}: {
  editorSDK: FlowEditorSDK;
}) => {
  const metaSiteId = await editorSDK.document.info.getMetaSiteId('token');
  const editorSDKVersion = editorSDK.info.getSdkVersion('token');

  editorSDK.editor.openModalPanel('token', {
    url: `https://www.wix.com/_serverless/dynamic-settings-renderer?wix-sdk-version=${
      editorSDKVersion.scriptSrc
    }&openWidgetsModal=true&componentId=not_exists&metaSiteId=${metaSiteId}`,
    shouldHideHeader: true,
    width: 680,
    height: 423,
  });
};
