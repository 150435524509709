import { DeviceType, EventType } from '@wix/platform-editor-sdk';
import { EditorReadyFn, GetAppManifestFn } from '@wix/yoshi-flow-editor';
import {
  IWebComponent,
  componentType,
} from './components/webComponent/elements/customElement';
import { configureWidgetManifest } from './components/webComponent/manifest';
import { HOTEL_RUNNER_APP_ID } from './hotels/hotels.constants';
import {
  deleteMetaSiteCacheData,
  getMetaSiteCacheData,
  updateComponentsData,
} from './utils/editor';
import { AppData, firstInstallFlow } from './utils/firstInstallFlow';
import {
  addComponentToPage,
  reportBiEventGfppSettingsClicked,
  saveSettingsToThirdPartyService,
  updateViewMode,
} from './utils/helpers';
import { updateInstanceId } from './utils/update-instance-id';
import { addMyBusinessPanelConfigurationToHotels } from './hotels/hotels-my-business-panel-config';

const TOKEN = 'TOKEN';

export const editorReady: EditorReadyFn = async (
  editorSDK,
  appDefinitionId,
  editorOptions,
  flowApi
) => {
  const { firstInstall } = editorOptions;
  const appData: AppData = await editorSDK.document.tpa.app.getDataByAppDefId(
    appDefinitionId,
    appDefinitionId
  );
  const isHotels = appData.appDefinitionId === HOTEL_RUNNER_APP_ID;

  // update the instance-id with the current instance-id in order to
  // solve a bug when duplicating a site also duplicates the instance-id
  try {
    const { originInstanceId } =
      await editorSDK.document.info.getAppInstancePayload(TOKEN);
    const isDuplicatedSite = Boolean(originInstanceId);
    if (isDuplicatedSite) {
      await updateInstanceId(editorSDK, appData, originInstanceId);
    }
  } catch (error) {
    console.error('error updating instance id for duplicate site flow ', {
      error,
    });
  }

  const metaSiteData = await getMetaSiteCacheData(editorSDK);

  if (metaSiteData) {
    await deleteMetaSiteCacheData(editorSDK);
  }

  const webComponents: IWebComponent[] =
    appData?.components?.filter((comp: any) => comp?.type === 'WEB') ?? [];

  const pages = await editorSDK.pages.data.getAll('');
  const hasHotelRunnerPage = pages.some(
    (page) => page.managingAppDefId === HOTEL_RUNNER_APP_ID
  );

  const hotelRunnerFirstInstall = isHotels && !hasHotelRunnerPage;

  await editorSDK.document.application.reloadManifest();

  //  first install is broken from the editor, it will always be true. I am leaving this logic for now, but fixing it for hotels to be based on if the essential comp is installed or not.
  if ((!isHotels && firstInstall) || hotelRunnerFirstInstall) {
    firstInstallFlow({
      editorSDK,
      webComponents,
      metaSiteData,
      flowApi,
      appData,
      appDefinitionId,
      TOKEN,
    });
  }

  const customElementComps = await editorSDK.document.components.findAllByType(
    TOKEN,
    {
      componentType,
    }
  );

  console.log('custom element components', customElementComps);

  // update changes of custom element components (scriptTag)
  updateComponentsData({
    editorSDK,
    instanceId: appData.instanceId,
    webComponents,
    components: customElementComps,
  });

  editorSDK.addEventListener(EventType.widgetAdded, async (data) => {
    const pageRef = await editorSDK.pages.getCurrent(TOKEN);

    console.log('widget added', data.detail);

    // once this is only called for the correct widgets it will work
    const widgetToAdd = webComponents.find((comp) => {
      return comp.componentId === data.detail.widgetId;
    });

    if (widgetToAdd) {
      // the editor adds the widget on its own. we cannot allow that so we first remove the broken widget the editor added

      const comps = await editorSDK.document.components.getAllComponents(TOKEN);
      const compExists = comps.some(
        (comp) => comp.id === data.detail.componentRef.id
      );
      console.log('compExists', compExists);
      if (compExists) {
        await editorSDK.document.components.remove(TOKEN, {
          componentRef: data.detail.componentRef,
        });
      }

      // then we add it ourselves
      await addComponentToPage({
        flowApi,
        componentDefinition: widgetToAdd,
        editorSDK,
        instanceId: appData.instanceId,
        applicationId: appData.appDefinitionId,
        metaSiteData,
        pageRef,
      });
    }
  });

  editorSDK.addEventListener(EventType.widgetGfppClicked, async (event) => {
    const { detail } = event;

    if (detail.id === 'connect') {
      reportBiEventGfppSettingsClicked(
        flowApi,
        editorSDK,
        event,
        webComponents
      );
    }
  });
  editorSDK.addEventListener(EventType.switchedToMobileView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Mobile,
        compRef,
        appDefinitionId,
      });
    }
  });

  editorSDK.addEventListener(EventType.switchedToDesktopView, async () => {
    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      }
    );

    for (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        mode: DeviceType.Desktop,
        compRef,
        appDefinitionId,
      });
    }
  });

  editorSDK.addEventListener(EventType.siteWasPublished, async () => {
    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            componentId: component.componentId,
            instanceId: appData.instanceId,
            status: 'published',
          },
        });
    }
  });

  editorSDK.addEventListener(EventType.themeChanged, async (e) => {
    //@ts-ignore
    if (e?.detail?.changeType === 'STYLE') return;

    const [colors, fonts] = await Promise.all([
      editorSDK.document.theme.colors.getAll(TOKEN),
      editorSDK.document.theme.fonts.getMap(TOKEN),
    ]);

    for (const component of webComponents) {
      component.data?.gfppSettings?.fetchInitialData &&
        saveSettingsToThirdPartyService({
          appId: appDefinitionId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            data: {},
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
    }

    const componentsByType = await editorSDK.document.components.findAllByType(
      'token',
      {
        componentType: 'wixui.CustomElementComponent',
      }
    );

    for await (const compRef of componentsByType) {
      updateViewMode({
        editorSDK,
        compRef,
        appDefinitionId,
        reset: true,
      });
    }
  });

  editorSDK.addEventListener(
    EventType.anyComponentAddedToStage,
    async (data) => {
      const fullData: any = await editorSDK.components.data.get(TOKEN, {
        componentRef: data.detail.compRef,
      });
      // controllerType is the component Id
      const { applicationId, controllerType } = fullData;

      const appDataToUpdate: any =
        await editorSDK.document.tpa.app.getDataByAppDefId(
          applicationId,
          applicationId
        );
      const component: any = appDataToUpdate.components?.find(
        (comp: any) => comp.componentId === controllerType
      );

      if (component.data?.gfppSettings?.fetchInitialData) {
        const [colors, fonts] = await Promise.all([
          editorSDK.document.theme.colors.getAll(TOKEN),
          editorSDK.document.theme.fonts.getMap(TOKEN),
        ]);

        saveSettingsToThirdPartyService({
          appId: applicationId,
          flowApi,
          url: component.data?.gfppSettings?.fetchInitialData,
          settings: {
            instanceId: appData.instanceId,
            theme: {
              colors,
              fonts,
            },
          },
        });
      }
    }
  );
};

export const getAppManifest: GetAppManifestFn = async (
  { appManifestBuilder },
  editorSDK,
  { initialAppData }
) => {
  const { appDefinitionId } = initialAppData;

  //widget gfpp settings
  await configureWidgetManifest(appManifestBuilder, editorSDK, appDefinitionId);

  if (appDefinitionId === HOTEL_RUNNER_APP_ID) {
    addMyBusinessPanelConfigurationToHotels({
      appManifestBuilder,
      editorSDK,
      initialAppData,
    });
  }

  // configure page options
  return appManifestBuilder
    .configurePages((pagesBuilder) => {
      // page settings
      pagesBuilder
        .addSettingsTab(
          {
            title: 'Page Info',
            helpId: '2fd96dc5-ff35-4ead-9917-12b487c59fe4',
            type: 'page_info',
          },
          {
            title: 'Layout',
            type: 'layout',
          },
          {
            title: 'Permissions',
            type: 'permissions',
          },
          {
            title: 'SEO',
            helpId: 'd243ad48-2e17-4786-99d7-23d011aa4bd6',
            type: 'seo',
          }
        )
        // page actions
        .addAction(
          {
            type: 'page_rename',
          },
          {
            type: 'page_delete',
          }
        );
    })
    .build();
};
